<template>
  <v-container>
    <div class="d-flex flex-row flex-grow-1 mb-3">
      <PageTitle
        text="Change Password"
        class=" ft font-weight-bold font-size-md"
      />
    </div>
    <div
      class="d-flex flex-column flex-grow-1 mr-1 mt-3"
      style="flex-basis: 50%;"
    >
      <span class="ft font-weight-medium font-size-md mb-2"
        >You can change your password here.</span
      >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(changePassword)">
          <div class="d-flex flex-row flex-grow-1 mt-3">
            <ValidationProvider
              tag="div"
              v-slot="{ errors }"
              rules="required"
              class="d-flex flex-grow-1"
            >
              <v-text-field
                outlined
                type="password"
                :error-messages="errors[0]"
                v-model="password.oldPassword"
                prepend-icon="lock"
                label="Enter your old password."
                class=" ft font-weight-medium font-size-md ma-1"
              />
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required|password:@confirm"
              v-slot="{ errors }"
              class="d-flex flex-grow-1"
            >
              <v-text-field
                outlined
                type="password"
                v-model="password.newPassword"
                :error-messages="errors[0]"
                prepend-icon="lock"
                label="Enter your new password."
                class=" ft font-weight-medium font-size-md ma-1"
              />
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              v-slot="{ errors }"
              name="confirm"
              class="d-flex flex-grow-1"
            >
              <v-text-field
                outlined
                type="password"
                :error-messages="errors[0]"
                v-model="password.confirmPassword"
                prepend-icon="lock"
                label="Enter your confirm password."
                class=" ft font-weight-medium font-size-md ma-1"
              />
            </ValidationProvider>
          </div>
          <div class="d-flex flex-row flex-grow-1 justify-end">
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              <span class="ft font-weight-medium font-size-sm"
                >Change Password</span
              >
            </v-btn>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import SnackBarMixins from "../../../mixins/SnackBarMixins";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field must only contain numbers"
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});
export default {
  name: "ChangePassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    PageTitle: () => import(`../../../components/PageTitle`),
    SnackBar: () => import(`../../../components/SnackBar`)
  },
  mixins: [SnackBarMixins],
  computed: {
    ...mapGetters({
      loading: "getIsLoading"
    })
  },
  data() {
    return {
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }
    };
  },
  methods: {
    changePassword() {
      this.$store.dispatch("auth/changePassword", this.password);
    }
  },
  created() {}
};
</script>
