var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mb-3"},[_c('PageTitle',{staticClass:" ft font-weight-bold font-size-md",attrs:{"text":"Change Password"}})],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-1 mt-3",staticStyle:{"flex-basis":"50%"}},[_c('span',{staticClass:"ft font-weight-medium font-size-md mb-2"},[_vm._v("You can change your password here.")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-3"},[_c('ValidationProvider',{staticClass:"d-flex flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-md ma-1",attrs:{"outlined":"","type":"password","error-messages":errors[0],"prepend-icon":"lock","label":"Enter your old password."},model:{value:(_vm.password.oldPassword),callback:function ($$v) {_vm.$set(_vm.password, "oldPassword", $$v)},expression:"password.oldPassword"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-grow-1",attrs:{"tag":"div","rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-md ma-1",attrs:{"outlined":"","type":"password","error-messages":errors[0],"prepend-icon":"lock","label":"Enter your new password."},model:{value:(_vm.password.newPassword),callback:function ($$v) {_vm.$set(_vm.password, "newPassword", $$v)},expression:"password.newPassword"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-grow-1",attrs:{"tag":"div","rules":"required","name":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:" ft font-weight-medium font-size-md ma-1",attrs:{"outlined":"","type":"password","error-messages":errors[0],"prepend-icon":"lock","label":"Enter your confirm password."},model:{value:(_vm.password.confirmPassword),callback:function ($$v) {_vm.$set(_vm.password, "confirmPassword", $$v)},expression:"password.confirmPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-end"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Change Password")])])],1)])]}}])})],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }